.sectionBody{
    padding: 20px;
}
.sectionBody .sectionHeading{
    font-family: none;
    font-weight: 700;
}
.sectionBody .sectionHeading .sectionNumber{
    font-style: italic;
    font-family: none;
    font-weight: 700;
    padding-right: 10px;
}
table.pdfTable{
    margin-top: 15px;
}
table.pdfTable, table.pdfTable th, table.pdfTable td {
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 14px;
    font-family: none;
    padding: 5px;
    text-wrap: wrap;
}
table.pdfTable th{
    font-weight: 700;
}