.pointer {
  width: 200px;
  height: 40px;
  position: relative;
  background: red;
}

.pointer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;

  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.pointer:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid red;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.key-merits-section,
.key-considerations-section {
  margin-left: -24px;
}

.key-merits-section li,
.key-considerations-section li {
  margin-top: 20px;
}

.key-merits-section .sub-heading,
.key-considerations-section .sub-heading {
  font-weight: bold;
}

.priliminary-investment-container ul li,
.other-relavent-container ul li {
  margin-top: 20px;
}

.financial-overview-container img {
  cursor: pointer;
  margin: auto;
  display: block;
  margin-top: 70px;
  height: 300px;
}
.flipped,
.flipped .content {
  transform: rotateX(180deg);
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) rgba(255, 255, 255, 0.1);
}

/* CSS for a sleek scrollbar within a specific container */
.container-with-scrollbar {
  /* Hide scrollbar for IE, Edge, and Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) rgba(255, 255, 255, 0.1);

  /* Thin scrollbar track */
  overflow-y: auto; /* Ensure scrollable content */
  max-height: 80vh;
}

/* Thin scrollbar track */
.container-with-scrollbar::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
.container-with-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

/* Handle on hover */
.container-with-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

/* for tab with diffrent class */

/* CSS for a sleek scrollbar within a specific container */
.container-with-scrollbar-tab {
  /* Hide scrollbar for IE, Edge, and Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) rgba(255, 255, 255, 0.1);

  /* Thin scrollbar track */
  overflow-y: auto; /* Ensure scrollable content */
  max-height: 60vh;
}

/* Thin scrollbar track */
.container-with-scrollbar-tab::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
.container-with-scrollbar-tab::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

/* Handle on hover */
.container-with-scrollbar-tab::-webkit-scrollbar-thumb:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

.legends {
  list-style-type: none;
  padding: 0;
  position: absolute;
  top: 0;
  left: 20px;
}

.legends li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  font-size: 12px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.dot.grey {
  background-color: #eaeaf0;
}

.dot.orange {
  background-color: orange;
}

.dot.red {
  background-color: red;
}

.dot.green {
  background-color: green;
}

.dot.aqua {
  background-color: aqua;
}

.dot.yellow {
  background-color: yellow;
}

/* .table-container {
  max-height: 80vh;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) rgba(255, 255, 255, 0.1);
} */
/* Thin scrollbar track */
.table-container::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
.table-container::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

/* Handle on hover */
.table-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(155, 155, 155, 0.8);
}
.fixed-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1px;
}

.fixed-table th:nth-child(2) {
  min-width: 300px;
}

.fixed-table td:nth-child(1),
.fixed-table td:nth-child(12),
.fixed-table td:nth-child(13) {
  min-width: 175px;
}
.fixed-table th,
.fixed-table td {
  border: 1px solid #000000;
}

.fixed-table th,
.fixed-table td {
  text-align: left;
  color: #000000;
  padding: 12px;
  min-width: 100px;
}

/* .fixed-table tr:nth-of-type(even) {
  background-color: #f7f8f8;
}

.fixed-table tr:nth-of-type(odd) {
  background-color: #ffffff;
} */

.fixed-table td:nth-child(1),
.fixed-table th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 9;
}

.fixed-table th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #002060;
  color: #ffffff;
  font-weight: bold;
}

.fixed-table th:nth-child(1) {
  z-index: 99;
}
.fixed-table td:nth-child(1) {
  background-color: #ffffff;
}

.markdownArea {
  padding: 16px 24px;
}
.markdownArea .markdownBox {
  margin-bottom: 10px;
}
.markdownArea li:has(> span) {
  list-style-type: none;
}
.markdownArea p {
  margin: 5px 0;
}
.markdownArea ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 30px;
}
.markdownArea code {
  white-space: normal;
}

.pageNumber {
  display: flex;
  /* justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; */
}

.circular-button {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #292f62;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  /* font-weight: bold; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0px 6px 6px; /* Adding margin to separate the buttons */
}

.circular-button:hover {
  background-color: #292f62;
}

.button-container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping of flex items */
  gap: 10px; /* Add some spacing between buttons */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180vh; /* Adjust width as needed */
  height: 85vh; /* Adjust height as needed */
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border-radius: 8px;
  /* overflow: hidden; */
}

.popup-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 40px;
  overflow: hidden;
}

/* .popup img {
  max-width: 90%;
  max-height: 90%;
} */

.popup-content::before {
  content: "✕";
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(20, 19, 19);
  font-size: 24px;
  cursor: pointer;
}

.cssClassData {
  width: 0.8em !important;
  height: 0.8em !important;
}

.scrollable-content {
  flex-grow: 1;
  overflow: auto; /* Enable both vertical and horizontal scrolling */
  width: 100%;
  height: 100%;
}

.scrollable-content img {
  min-width: 100%; /* Ensure image width is at least as wide as the container */
  min-height: 100%; /* Ensure image height is at least as tall as the container */
  width: auto;
  height: auto;
}

.editArea {
  width: 95%;
  float: left;
  padding: 0 20px;
  overflow: hidden;
}

.editArea input,
.editArea textarea {
  width: 100%;
}
.editChildBullets {
  padding-left: 20px;
}

.container {
  max-width: 600px;
  border-radius: 10px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.customFileInput {
  position: relative;
  display: inline-block;
}

.fileInput {
  display: none; /* Hide the original input */
}

.fileInputLabel {
  display: inline-block;
  padding: 5px;
  background-color: rgb(41, 47, 98);
  color: #fff;
  border-radius: 0;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  font-size: 12px;
  border: 1px solid rgb(41, 47, 98);
}

/* .fileInputLabel:hover {
  color: rgb(41, 47, 98);
    background-color: #ffffff;
    border: 1px solid rgb(41, 47, 98);
} */

.statusMark {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressBarWrapper {
  width: 50px;
  margin-left: auto;
}

.deleteButton {
  border: none;
  background: none;
  cursor: pointer;
}

.uploadButton {
  display: block;
  margin-top: 20px;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 0;
  cursor: pointer;
  text-align: center;
  width: 100px;
}

.reDraftloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 2000;
}

@keyframes zoomBlink {
  0% {
    transform: scale(1); /* Normal size */
    opacity: 1; /* Fully visible */
  }
  25% {
    transform: scale(1.2); /* Zoom in */
    opacity: 0.8; /* Slight fade */
  }
  50% {
    transform: scale(0.8); /* Zoom out */
    opacity: 0.6; /* More faded */
  }
  75% {
    transform: scale(1.1); /* Slight zoom in */
    opacity: 1; /* Fully visible */
  }
  100% {
    transform: scale(1); /* Back to normal size */
    opacity: 1; /* Fully visible */
  }
}

.loaderPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 2000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Start at 0 degrees */
  }
  50% {
    transform: rotate(180deg); /* Half rotation */
  }
  100% {
    transform: rotate(360deg); /* Full rotation */
  }
}

.fileList {
  list-style: none; /* Remove default list styling */
  padding: 0;
  margin: 0;
}

.fileItem {
  display: flex; /* Align children horizontally */
  align-items: center; /* Center vertically */
  gap: 10px; /* Add space between items */
  margin-bottom: 15px;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.fileName {
  flex-grow: 1; /* Allow the fileName to take available space */
  white-space: normal; /* Allow text to wrap into multiple lines */
  overflow: hidden; /* Hide overflowing text */
  word-wrap: break-word; /* Break words if they're too long */
  /* max-width: 150vh; */
}

.uploadedSize {
  white-space: nowrap; /* Keep the size on one line */
}

.deleteSection {
  white-space: nowrap; /* Keep the delete button on one line */
}

.formArea {
  margin: 20px 30px 0;
  display: flex;
  justify-content: flex-start;
  background: #ffffff;
  padding: 5px 15px 25px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.MuiStepLabel-label{
  font-size: 12px !important;
  margin: 20px 0;
}