.table-container {
    display: grid;
    grid-template-columns: repeat(9, auto);
    /* Define 3 columns */
    font-size: 14px;
  }
  
  .header {
    margin-bottom: 20px;
    color: #292f62;
    text-align: left;
    padding: 0 15px;
    font-weight: 500;
  }
  
  .accordion {
    grid-column: 1 / span 9;
    border-radius: 0;
    margin-bottom: 20px;
  }
  
  /* Styling for table body */
  .table-container > div {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    /* Add bottom border to each cell */
    padding: 15px;
    /* Padding for cells */
    margin-bottom: 15px;
    /* border-radius: 8px; */
    text-align: left;
  }
  
  .expanded {
    background-color: #292f62;
    color: white !important;
  }
  
  .last-col {
    border-right: 1px solid #ddd;
    border-radius: 0;
  }
  
  .sec-col {
    border-left: 1px solid #ddd;
    border-radius: 0;
  }
  
  .row-id {
    position: relative;
    left: -24px;
    top: -13px;
    font-size: 12px;
    color: #1f1e1e;
  }
  
  .export-btn-container {
    display: flex;
  }
  .export-btn-container aside:nth-child(1) svg {
    font-size: 24px;
  }
  .export-btn-container aside:nth-child(2) svg {
    font-size: 22px;
  }
  .export-btn-container button {
    color: #292f62;
    padding: 0;
  }
  
  .sort-table-doc-desc:after {
    content: "▼" / "";
    position: absolute;
    /* right: 30%; */
    /* bottom: 91%; */
    font-size: 9px;
    opacity: 0.4;
    top: 10px;
    cursor: pointer;
    right: 0;
  }
  
  .sort-table-doc-asc:before {
    content: "▲" / "";
    position: absolute;
    font-size: 9px;
    opacity: 0.4;
    top: 0;
    cursor: pointer;
    right: 0;
  }
  .sort-table-doc-asc-active:before,
  .sort-table-date-modified-asc-active:before,
  .sort-table-created-by-asc-active:before {
    opacity: 0.9;
    color: #292f62;
  }
  .sort-table-doc-desc-active:after,
  .sort-table-date-modified-desc-active:after,
  .sort-table-created-by-desc-active:after {
    opacity: 0.9;
    color: #292f62;
  }
  