.logo {
    width: 82.99px;
    height: 145.04px;
    margin-top: 80px;

}

.loginBox {
    margin-left: 12%;
}

.sign-text {
    font-size: 16px !important;
    font-weight: 400 !important;
    height: 30px;
}

.microsoft-icon {
    margin-left: 14px;
}

.login-copyright-text {
    /* font-family: Roboto; */
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18.75px !important;

}

/* .loginText {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;

} */