.dashboard-heading {
  display: flex;
  justify-content: space-between;
  padding: 30px 40px 30px 47px;
}

.filter-container {
  margin: 20px 30px 0;
  display: flex;
  justify-content: flex-start;
  background: #ffffff;
  padding: 5px 15px 25px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.filter-container .MuiStack-root {
  padding-top: 10px;
  margin: 0 15px;
}
.filter-container .MuiStack-root .MuiTextField-root {
  min-width: auto;
}
.filter-container .MuiStack-root label {
  top: -4px;
}
.filter-container .MuiSelect-select{
  font-size: 14px; 
}
.MuiList-root li {
  font-size: 14px;
}
.date-picker-container {
  display: flex;
  justify-content: space-between;
}
.home-page-heading {
  font-size: 19px !important;
  font-weight: 600 !important;
  margin-left: 12px !important;
  margin-top: 8px !important;
}

.home-page-left-heding {
  display: flex;
}

.create-new-doc-btn-text {
  font-size: 13px !important;
}

.loaderPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 2000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Start at 0 degrees */
  }
  50% {
    transform: rotate(180deg); /* Half rotation */
  }
  100% {
    transform: rotate(360deg); /* Full rotation */
  }
}

.divider{
  height: 1px;
  background-color: #e0e0e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 50px 70px 0;
}