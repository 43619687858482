.adminPanel {
  display: flex;
  justify-content: space-between;
  padding: 30px 15px 5px 15px;
}

.adminPanel thead tr th {
  color: #292f62;
  font-weight: 600;
  padding: 8px;
  text-align: center;
  font-size: 12px;
  width:200px
}

.adminPanel tbody tr td {
  padding: 8px 8px;
  text-align: center;
  font-size: 12px;
  width:200px
}
.adminPanel button {
  text-transform: capitalize;
  border-radius: 0;
}
.tabArea {
  border-right: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
  min-width: 200px;
}
.tabArea button {
  align-items: start;
}
.contentArea {
  flex: 1;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.addPortfolioArea {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
/* Modal Styles */
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: var(--background-paper, #fff);
  padding: 15px 24px 24px;
  box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.12);
  border: 1px solid #ccc;
}

/* Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.modal-title {
  margin: 0;
  font-weight: 400;
}

/* Input Fields */
.compact-input .MuiInputBase-root,
.compact-input .MuiInputBase-fullWidth {
  height: 40px !important;
  font-size: 0.875rem;
  padding-right: 0;
}

.compact-input .MuiInputLabel-root {
  font-size: 0.75rem;
  top: 3px;
}
.compact-select .MuiInputLabel-root {
  top: -3px;
}
.compact-input.get-input .MuiInputLabel-root {
  top: -3px;
}

.compact-input .MuiInputBase-input::placeholder {
  font-size: 0.75rem; /* Smaller placeholder text */
  color: #9e9e9e; /* Optional: Adjust placeholder color for better visibility */
}

/* Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

/* Submit Button */
.modal-footer .submit-button {
  text-transform: capitalize;
  border-radius: 0;
}

.compact-input.get-input .MuiInputBase-root,
.compact-input.upload-input .MuiInputBase-root {
  padding-right: 4px;
}

.compact-input .get-button {
  text-transform: capitalize;
  border-radius: 0;
  margin-right: 5px;
  background-color: #292f62;
}
.get-input .get-button {
  margin-right: 0;
}

.uploadArea {
  margin: 15px 0 0;
}

.uploadArea .customSelect label {
  border-radius: 0;
}

.newFilesWrapper {
  width: 100%;
}

.newFilesHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ccc;
  font-weight: bold;
}

.headerItem {
  text-align: left;
}

.newFilesList {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}
.uploadStatus {
  padding: 8px 8px 0;
}

.newFileItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.newFileName,
.newFileDate,
.newFileSize,
.newActionsWrapper {
  display: flex;
  align-items: center;
}

.newActionsWrapper {
  justify-content: flex-start;
}

.restoreCheckbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #292f62;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.restoreCheckbox:checked {
  background-color: #292f62;
  border-color: #292f62;
}

.restoreCheckbox:checked::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 0;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.restoreCheckbox:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  border-color: #cccccc;
  background-color: #f5f5f5;
}

.restoreCheckbox:disabled:checked {
  background-color: #cccccc;
}

.datepicker-container-horizontal {
  display: flex;
  gap: 20px;
}

.input-wrapper {
  position: relative;
  flex: 1;
  margin: 0 0 0 10px;
}

.datepicker-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: transparent;
}

.floating-label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
}

.floating-label.active {
  top: -6px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  background: #f8f8f8;
}

.datepicker-input:focus + .floating-label,
.datepicker-input:not(:focus):valid + .floating-label {
  top: -10px;
  font-size: 12px;
  color: #007bff;
}

.datepicker-input:focus {
  outline: none;
}

/* Modify layout of inputs side by side */
.datepicker-container-horizontal {
  display: flex;
  gap: 20px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #292f62;
}
